<template>
  <div class="myfilters poppins" style="z-index: 10 !important">
    <div v-if="filter['type'] === 'report_date_month_range'">
      <v-select
        class="px-2"
        dense
        v-model="queryObject[`${filter.name}.select`]"
        :items="dateTypes"
        item-text="text"
        item-value="value"
        @change="handleChange"
        :label="$t(filter.title.en ? filter.title.en : filter.title)"
        prepend-inner-icon="mdi-calendar-range"
      >
        <template v-slot:item="{ item }"
          ><div
            class="py-4"
            style="height: 100%; width: 100%; display: block"
            @click="updateDateOfCharts(item.value)"
          >
            {{ item.text }}
          </div>
        </template>
      </v-select>
      <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
        <v-date-picker
          v-model="queryObject[`${filter.name}.date`]"
          range
          type="month"
          scrollable
          @change="handleChange"
          :max="new Date().toISOString().split('T')[0]"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="
              () => {
                dialog = false;
                isVisible = true;
                dateType = 'this_month';
              }
            "
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn text color="primary" @click="updateDateOfCharts('custom')">
            {{ $t("ok") }}
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </div>
    <div v-if="filter['type'] === 'select2Single'">
      <v-autocomplete
        @change="handleChange"
        class="mx-1"
        :items="filter['values']"
        v-model="queryObject[`${filter.name}`]"
        :label="$t(filter.title)"
        item-text="text"
        item-value="index"
        dense
        clearable
      ></v-autocomplete>
    </div>
    <div v-if="filter['type'] === 'autocompleteMultiple'">
      <v-combobox
        @change="handleChange"
        class="mx-1"
        v-model="queryObject[`${filter.name}`]"
        :items="entries"
        :label="$t(filter.title)"
        :search-input.sync="q"
        item-text="name"
        item-value="id"
        :loading="isLoading"
        multiple
        dense
        clearable
      ></v-combobox>
    </div>
    <div v-if="filter['type'] === 'range'">
      <v-range-slider
        @change="handleChange"
        class="mx-1"
        v-model="queryObject[`${filter.name}`]"
        :label="$t(filter.title)"
        ticks
        tick-size="1"
        thumb-label
        min="0"
        max="100"
        dense
        clearable
      ></v-range-slider>
    </div>
    <div v-if="filter['type'] === 'phone'">
      <VueTelInputVuetify
        class="ma-0 pa-0 mx-1"
        v-model="queryObject[`${filter.name}`]"
        :inputOptions="{ showDialCode: true, tabindex: 0 }"
        mode="international"
        @change="handleChange"
        dense
        disabledFetchingCountry
        defaultCountry="AE"
      ></VueTelInputVuetify>
    </div>
    <div v-if="filter['type'] === 'multiImageSelect'">
      <v-autocomplete
        @change="handleChange"
        class="mx-1"
        multiple
        :items="filter['values']"
        v-model="queryObject[`${filter.name}`]"
        :label="$t(filter.title)"
        item-text="text"
        item-value="index"
        dense
        messages=""
        clearable
        small-chips
        deletable-chips
      >
        <template v-slot:item="{ item }">
          <show-url-image :url="item.image" class="mr-3"> </show-url-image>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="filter['type'] === 'multipleSelect'">
      <v-autocomplete
        @change="handleChange"
        class="mx-1"
        multiple
        :items="filter.values"
        v-model="queryObject[filter.name]"
        :label="$t(filter.title)"
        item-text="text"
        item-value="index"
        dense
        messages=""
        clearable
        small-chips
        deletable-chips
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon>
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title class="font-size-sm">
                {{ $t("select_all") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-autocomplete>
    </div>
    <div v-else-if="filter['type'] === 'date'">
      <v-dialog
        ref="dialog"
        v-model="menu2"
        class="poppins"
        transition="dialog-bottom-transition"
        content-class="mx-0 d-flex justify-content-center align-items-center elevation-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :label="$t(filter.title)"
            append-icon="mdi-calendar"
            class="mx-1"
            clearable
            dense
            readonly
            v-bind="attrs"
            v-on="on"
            :value="inputValue"
            @click:clear="
              () => {
                queryObject[`${filter.name}`] = undefined;
                inputValue = null;
                setToInitialDatePickerValue();
                handleChange();
              }
            "
          />
        </template>
        <template v-slot:default v-if="menu2 === true">
          <date-range-picker
            ref="picker"
            opens="inline"
            :close-on-esc="false"
            :showDropdowns="true"
            min-date="01-01-2018"
            :max-date="new Date().toLocaleDateString()"
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
            control-container-class="form-control border-0 bg-transparent d-none"
            class="max-w-700px"
            v-model="datePickerLocalValue"
          >
            <template v-slot:footer="picker">
              <div class="drp-buttons py-4 px-6 poppins">
                <button
                  type="button"
                  class="btn btn-light ls1 py-2 px-7"
                  @click="
                    () => {
                      picker.clickCancel();
                      setToInitialDatePickerValue();
                      queryObject[`${filter.name}`] = undefined;
                      inputValue = null;
                      handleChange();
                      handleToggleOfDatePicker(picker);
                    }
                  "
                >
                  Cancel</button
                ><button
                  @click="
                    () => {
                      picker.clickApply();
                      inputValue = picker.rangeText;
                      handleChange();
                      handleToggleOfDatePicker();
                    }
                  "
                  type="button"
                  class="btn btn--export-filter ls1 py-2 px-4"
                >
                  Apply
                </button>
              </div>
            </template>
          </date-range-picker>
        </template>
      </v-dialog>
    </div>
    <div v-else-if="filter['type'] === 'single_date'">
      <v-dialog
        ref="dialog"
        v-model="menu2"
        class="poppins"
        transition="dialog-bottom-transition"
        content-class="mx-0 d-flex justify-content-center align-items-center elevation-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :label="$t(filter.title)"
            append-icon="mdi-calendar"
            class="mx-1"
            clearable
            dense
            readonly
            v-bind="attrs"
            v-on="on"
            :value="inputValue"
            @click:clear="
              () => {
                queryObject[`${filter.name}`] = undefined;
                inputValue = null;
                setToInitialDatePickerValue();
                handleChange();
              }
            "
          />
        </template>
        <template v-slot:default v-if="menu2 === true">
          <date-range-picker
            ref="picker"
            opens="inline"
            :close-on-esc="false"
            :showDropdowns="true"
            min-date="01-01-2018"
            :max-date="new Date().toLocaleDateString()"
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
            control-container-class="form-control border-0 bg-transparent d-none"
            class="max-w-300px"
            :singleDatePicker="'single'"
            v-model="datePickerLocalValue"
            :ranges="false"
          >
            <template v-slot:footer="picker">
              <div class="drp-buttons py-4 px-6 poppins">
                <button
                  type="button"
                  class="btn btn-light ls1 py-2 px-7"
                  @click="
                    () => {
                      picker.clickCancel();
                      setToInitialDatePickerValue();
                      queryObject[`${filter.name}`] = undefined;
                      inputValue = null;
                      handleChange();
                      handleToggleOfDatePicker(picker);
                    }
                  "
                >
                  Cancel</button
                ><button
                  @click="
                    () => {
                      picker.clickApply();
                      inputValue = picker.rangeText;
                      handleChange();
                      handleToggleOfDatePicker();
                    }
                  "
                  type="button"
                  class="btn btn--export-filter ls1 py-2 px-4"
                >
                  Apply
                </button>
              </div>
            </template>
          </date-range-picker>
        </template>
      </v-dialog>
    </div>
    <div v-else-if="filter['type'] === 'select2DateRange'">
      <v-dialog
        ref="dialog"
        v-model="menu2"
        class="poppins"
        transition="dialog-bottom-transition"
        content-class="mx-0 d-flex justify-content-center align-items-center elevation-0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Date range"
            append-icon="mdi-calendar"
            class="mx-1"
            clearable
            dense
            v-bind="attrs"
            v-on="on"
            :value="inputValue"
            @click:clear="
              () => {
                queryObject[`${filter.name}`] = undefined;
                queryObject[`${filter.name}.select`] = filter.values[0].index;
                inputValue = null;
                setToInitialDatePickerValue();
                handleChange();
              }
            "
          />
        </template>
        <template v-slot:default v-if="menu2 === true">
          <date-range-picker
            ref="picker"
            opens="inline"
            :close-on-esc="false"
            :showDropdowns="true"
            min-date="01-01-2018"
            :max-date="new Date().toLocaleDateString()"
            :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd HH:MM' }"
            control-container-class="form-control border-0 bg-transparent d-none"
            class="max-w-700px"
            v-model="datePickerLocalValue"
            timePicker
            :time-picker-increment="1"
          >
            <template v-slot:footer="picker">
              <div
                class="drp-buttons py-4 px-6 poppins d-flex justify-content-between align-items-center"
              >
                <div>
                  <v-select
                    outlined
                    label="Filter by"
                    class="poppins w-75"
                    v-model="queryObject[`${filter.name}.select`]"
                    :items="filter.values"
                    item-text="text"
                    item-value="index"
                    dense
                    hide-details
                  />
                </div>

                <div class="d-flex align-center">
                  <button
                    type="button"
                    class="btn btn-light ls1 py-2 px-4"
                    @click="
                      () => {
                        picker.clickCancel();
                        setToInitialDatePickerValue();
                        queryObject[`${filter.name}`] = undefined;
                        inputValue = null;
                        handleChange();
                        handleToggleOfDatePicker(picker);
                      }
                    "
                  >
                    Cancel</button
                  ><button
                    type="button"
                    class="btn btn--print-upload ls1 py-2 px-3"
                    @click="
                      () => {
                        picker.clickApply();
                        inputValue = picker.rangeText;
                        handleChange();
                        handleToggleOfDatePicker();
                      }
                    "
                  >
                    Apply
                  </button>
                </div>
              </div>
            </template>
          </date-range-picker>
        </template>
      </v-dialog>
    </div>
    <div v-else-if="filter['type'] === 'inputText'">
      <v-text-field
        class="mx-1"
        v-model="queryObject[`${filter.name}`]"
        @keydown="isTextInputEnterHited"
        @change="handleChange"
        :label="$t(filter['title'])"
        required
        clearable
        dense
      ></v-text-field>
    </div>
    <div v-else-if="filter['type'] === 'inputTextMultiple'" class="scrollable">
      <v-combobox
        class="multi-select mx-1"
        dense
        v-model="queryObject[`${filter.name}`]"
        :hide-no-data="!queryObject[`${filter.name}`]"
        :label="$t(filter.title)"
        @change="
          handleChangeMultiple(queryObject[`${filter.name}`], filter.name)
        "
        multiple
        deletable-chips
        clearable
        allow-overflow
        small-chips
      >
      </v-combobox>
    </div>
  </div>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import ShowUrlImage from "@/own/components/datatable/ShowUrlImage.vue";
import ApiService from "@/core/services/api.service";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  name: "MyFilter",
  components: { VueTelInputVuetify, ShowUrlImage, DateRangePicker },
  props: {
    filter: {
      required: true,
    },
    submitFilterValues: {
      required: true,
    },
    defaultValues: {
      required: false,
      default: false,
    },
    filtersData: {
      required: true,
    },
  },
  data: () => ({
    queryObject: {},
    q: null,
    entries: [],
    isLoading: false,
    menu2: false,
    menu3: false,
    dialog: false,

    doneTypingInterval: 1500,
    typingTimer: null,
    inputValue: null,
    datePickerLocalValue: {},
  }),
  beforeMount() {
    this.$set(this.queryObject, this.filter.name, []);
  },
  mounted() {
    if (this.filter.type === "select2DateRange") {
      this.setToInitialDatePickerValue();
      this.queryObject[`${this.filter.name}.select`] =
        this.filter.values[0].index;
    } else if (this.filter.type === "range") {
      this.queryObject[`${this.filter.name}`] = [
        this.filter.min,
        this.filter.max,
      ];
    } else if (this.filter.type === "date") {
      this.setToInitialDatePickerValue();
    } else if (this.filter.type === "single_date") {
      this.setToInitialDatePickerValue();
    }
    if (this.defaultValues) {
      this.queryObject = this.defaultValues;
    }
  },
  computed: {
    dateTypes() {
      return [
        { text: this.$t("this_year"), value: "this_year" },
        { text: this.$t("all_time"), value: "all_time" },
        { text: this.$t("custom"), value: "custom" },
      ];
    },
    selectedAll() {
      if (
        this.filter.type === "multipleSelect" &&
        Array.isArray(this.queryObject[this.filter.name])
      ) {
        return (
          this.queryObject[this.filter.name].length ===
          this.filter.values.length
        );
      } else return false;
    },
    selectedSome() {
      if (
        this.filter.type === "multipleSelect" &&
        Array.isArray(this.queryObject[this.filter.name])
      ) {
        return this.queryObject[this.filter.name].length > 0;
      } else return false;
    },
    icon() {
      if (this.selectedAll) return "mdi-checkbox-marked";
      if (this.selectedSome) return "mdi-minus-box-outline";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    handleToggleOfDatePicker() {
      setTimeout(() => {
        // picker.togglePicker(false);
        this.menu2 = false;
      }, 100);
    },
    setToInitialDatePickerValue() {
      let startDate = new Date();

      const startDatesMonth = startDate.getMonth();
      startDate.setMonth(startDatesMonth - 1);
      startDate.setDate(1);
      startDate.setHours(0, 0);

      let endDate = new Date();
      endDate.setHours(23, 59);
      this.datePickerLocalValue = { startDate, endDate };
    },
    toggle() {
      if (this.selectedAll) {
        this.$set(this.queryObject, this.filter.name, []);
      } else {
        let arr = this.filter.values.map((value) => value.index);
        this.$set(this.queryObject, this.filter.name, arr);
      }
      this.handleChange();
    },
    clearData: function() {
      if (this.filter.type === "multipleSelect")
        this.$set(this.queryObject, this.filter.name, []);
      else if (this.filter.type === "date") {
        this.queryObject = {};
        this.$emit("option", this.queryObject);
      } else this.queryObject = {};
      this.menu2 = false;
      this.q = null;
      this.entries = [];
    },
    handleChange() {
      if (this.filter.type == "autocompleteMultiple") {
        let multiCoboData = { ...this.queryObject };
        multiCoboData[`${this.filter.name}`] = multiCoboData[
          `${this.filter.name}`
        ].map((data) => {
          if (data) {
            return data.id;
          } else {
            return;
          }
        });
        this.$emit("option", multiCoboData);
      } else if (this.filter.type === "select2DateRange") {
        // console.log(this.queryObject);
        if (this.inputValue) {
          this.queryObject[`${this.filter.name}.date`] =
            this.inputValue.split(" - ");
        }
        this.$emit("option", this.queryObject);
      } else if (this.filter.type === "date") {
        // console.log(this.queryObject);
        if (this.inputValue) {
          this.queryObject[`${this.filter.name}`] =
            this.inputValue.split(" - ");
        }
        this.$emit("option", this.queryObject);
      } else if (this.filter.type === "single_date") {
        // console.log(this.queryObject);
        if (this.inputValue) {
          this.queryObject[this.filter.name] = this.inputValue;
        }
        this.$emit("option", this.queryObject);
      } else {
        this.$emit("option", this.queryObject);
      }
    },
    handleChangeMultiple(e, filter) {
      this.queryObject[filter] = this.seperateMultipleInput(e);
      this.$emit("option", this.queryObject);
    },
    isTextInputEnterHited(e) {
      if (e.keyCode === 13) {
        this.submitFilterValues();
      }
    },
    seperateMultipleInput(val) {
      let seperated = [];
      val.forEach((element) => {
        let subelement = element.trim().split(/(?:,| )+/);
        subelement.forEach((sub) => {
          seperated.push(sub);
        });
      });
      return seperated;
    },
    updateDateOfCharts(val) {
      if (val === "custom") {
        if (!this.dialog) {
          this.dialog = !this.dialog;
        } else {
          this.dialog = !this.dialog;
        }
      }
    },
    getData(value) {
      ApiService.post(this.filter.url, {
        q: value,
        filters: this.filtersData,
      })
        .then(({ data }) => {
          this.entries = data.locations;
          this.isLoading = false;
        })
        .catch()
        .finally(() => (this.isLoading = false));
    },
  },
  watch: {
    defaultValues(val) {
      this.queryObject = val;
    },
    q(val) {
      if (val && val !== "") {
        this.isLoading = true;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
          this.isLoading = true;
          this.getData(val.trim());
        }, this.doneTypingInterval);
      }
    },
  },
};
</script>

<style>
.myfilters .v-select__selections {
  height: 26px;
  overflow-y: scroll;
}
.myfilters .v-select__selections::-webkit-scrollbar {
  display: none;
}
</style>
