<template>
  <div>
    <div v-if="header.type === 'popup'">
      {{ item }}
    </div>
    <div v-else-if="header.type === 'service_popup'">
      <ShowServices :itemForAction="value" />
    </div>
    <div v-else-if="header.type === 'group'">
      <NameEmail :value="value" />
    </div>
    <div v-else-if="header.type === 'text' && header.value === 'url'">
      <text-column class="text-wrap" :header="header" :value="value" />
    </div>
    <div v-else-if="header.type === 'text' || header.type === 'id'">
      <text-column :value="value" :header="header" />
    </div>
    <div v-else-if="header.type === 'text_id'" :id="value">
      <text-column :value="value" :header="header" :id="value" />
    </div>
    <!--    <div v-else-if="header.type === 'userPhoto'">-->
    <!--      <UserPhoto :value="value"></UserPhoto>-->
    <!--    </div>-->
    <div v-else-if="header.type === 'showCustomers'">
      <ShowFinServiceCustomers :item="item" />
    </div>
    <div v-else-if="header.type === 'carrierGroup'">
      <CarrierPhoto :value="value" />
    </div>
    <div
      v-else-if="header.type === 'clientPhoto' || header.type === 'userPhoto'"
    >
      <ClientColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'showRoles'">
      <ShowRoles :roles="value.data" :title="value.title" />
    </div>
    <div v-else-if="header.type === 'showWarehouses'">
      <ShowWarehouses :roles="value.data" :title="value.title" />
    </div>
    <div v-else-if="header.type === 'userStatus'" class="w-70px">
      <StatusColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'status'" class="min-w-90px mx-2">
      <StatusColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'status_label'" class="w-90px">
      <StatusColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'label'" class="min-w-50px max-w-235px">
      <StatusColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'orderStatus'">
      <StatusColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'date'">
      <DateColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'time'">
      <DateTimeColumn :value="value" />
    </div>
    <div v-else-if="header.type === 'showSalePerson'">
      <ShowSalePerson :item="item" />
    </div>
    <div v-else-if="header.type === 'showClientSalePerson'">
      <ShowClientSalePerson :item="item" />
    </div>
    <div v-else-if="header.type === 'showEntities'">
      <ShowEntities :entities="value.data" :title="value.title" />
    </div>

    <div v-else-if="header.type === 'codHiddenData'">
      <CodHiddenData :ref="value.id" :value="value" :item="item" />
    </div>
    <div v-else-if="header.type === 'showCredentialPopup'">
      <ShowCredentialPopup :value="value" />
    </div>
    <div v-else-if="header.type === 'htmlpopup'">
      <ShowHtmlPopUp :value="value" />
    </div>
    <div v-else-if="header.type === 'store_group'">
      <StoreItem :store="value" />
    </div>
    <div v-else-if="header.type === 'showImage'">
      <ShowImage :item="value" />
    </div>
    <div v-else-if="header.type === 'buttonLike'">
      <button-like :value="value" />
    </div>
    <div v-else-if="header.type === 'urlImage' || header.type === 'photo'">
      <ShowUrlImage :url="value" />
    </div>
    <div v-else-if="header.type === 'platform_image'">
      <ShowOnlyImage :url="value" />
    </div>
    <div v-else-if="header.value === 'view_token'">
      <ShowToken :value="value" />
    </div>
    <div v-else-if="header.type === 'table'">
      <ShowSkusTable :value="value" />
    </div>
    <div v-else-if="header.type === 'table_with_timeline'">
      <ShowTableWithTimeline
        :header="header.text"
        :table="value.table"
        :timeline="value.timeline"
      />
    </div>
    <div v-else-if="header.type === 'popUpDatatable'">
      <PopUpDatatable :item="item" />
    </div>
    <div v-else-if="header.type === 'colorGroup'">
      <ColorGroup :color="value" />
    </div>
    <div
      v-else-if="
        header.type === 'multiLanguageText' ||
        header.type === 'multiLanguageHtml'
      "
    >
      <ShowCustomContent :itemForAction="value" :item="item" :header="header" />
    </div>
    <div v-else-if="header.type === 'mail_template'">
      <show-mail :itemForAction="item" />
    </div>
    <div v-else-if="header.type === 'json_data' || header.type === 'object'">
      <show-data :item="value" :header="header" />
    </div>
    <div
      v-else-if="
        header.type === 'json_data_api' ||
        header.type === 'object_api' ||
        header.type === 'objectApi'
      "
    >
      <show-data-from-api :item="item" :header="header" :value="value" />
    </div>

    <div v-else-if="header.type === 'validations_popup'">
      <validation-dialog :integration="item" :title="value.title" />
    </div>
    <div v-else-if="header.type === 'platform_action'">
      <platform-actions :itemForAction="value" />
    </div>
    <div v-else-if="header.type === 'download'">
      <download-file :value="value" />
    </div>
    <div v-else-if="header.type === 'list_modal'">
      <list-modal :value="value" />
    </div>
    <div v-else-if="header.type === 'social_link'">
      <SocialMediaGroup :data="value" />
    </div>
    <div v-else-if="header.type === 'star-rating'">
      <star-rating
        :rating="value"
        :star-size="20"
        read-only
        text-class="d-none"
      />
    </div>
    <div v-else-if="header.type === 'namePhotoEmail'">
      <NamePhotoEmail :header="header" :value="value" :item="item" />
    </div>
    <div v-else-if="header.type === 'subscriptionRules'">
      <ShowRulesTable :value="value" />
    </div>
    <div v-else-if="header.type === 'objectList'">
      <ObjectList :value="value" />
    </div>
    <div v-else-if="header.type === 'imageTitleDescription'">
      <ImageTitleDescription :header="header" :value="value" :item="item" />
    </div>
    <div v-else-if="header.type === 'errorLabel'">
      <ErrorLog :value="value" />
    </div>
    <div v-else-if="header.type === 'configuration_popup'">
      <ConfigurationPopup :configurations="value" :item="item" />
    </div>
    <div v-else>
      {{ value }}
    </div>
  </div>
</template>

<script>
/**
 * universal column display component for datatables
 * @var header
 * @var item
 * @var value
 */
import TextColumn from "@/own/components/datatable/TextColumn.vue";
import CarrierPhoto from "@/own/components/datatable/CarrierPhoto.vue";
import ClientColumn from "@/own/components/datatable/ClientColumn.vue";
import ShowRoles from "@/own/components/datatable/ShowRoles.vue";
import ShowWarehouses from "@/own/components/datatable/ShowWarehouses.vue";
import DateColumn from "@/own/components/datatable/DateColumn.vue";
import DateTimeColumn from "@/own/components/datatable/DateTimeColumn.vue";
import ShowSalePerson from "@/own/components/datatable/ShowSalePerson";
import ShowClientSalePerson from "@/own/components/datatable/ShowClientSalePerson";
import ShowEntities from "@/own/components/datatable/ShowEntities.vue";
import CodHiddenData from "@/own/components/datatable/CodHiddenData.vue";
import ShowHtmlPopUp from "@/own/components/datatable/ShowHtmlPopUp.vue";
import ShowCredentialPopup from "@/own/components/datatable/ShowCredentialPopup.vue";
import StoreItem from "@/own/components/datatable/StoreItem.vue";
import NameEmail from "@/own/components/datatable/NameEmail.vue";
import StatusColumn from "@/own/components/datatable/StatusColumn";
import ShowImage from "@/own/components/datatable/ShowImage";
import ShowFinServiceCustomers from "@/own/components/datatable/ShowFinServiceCustomers";
import ButtonLike from "@/own/components/datatable/ButtonLike.vue";
import ShowUrlImage from "@/own/components/datatable/ShowUrlImage.vue";
import ShowOnlyImage from "@/own/components/datatable/ShowOnlyImage.vue";
import PopUpDatatable from "@/own/components/datatable/PopUpDatatable";
import ShowSkusTable from "@/own/components/datatable/ShowSkusTable";
import ShowData from "@/own/components/datatable/ShowData.vue";
import ValidationDialog from "@/own/components/datahub/integrations/validation/ValidationDialog.vue";
import PlatformActions from "@/own/components/datatable/PlatformActions.vue";
import ShowCustomContent from "@/own/components/datatable/ShowCustomContent.vue";
import ShowMail from "@/own/components/datatable/ShowMail.vue";
import ColorGroup from "@/own/components/datatable/ColorGroup.vue";
import DownloadFile from "@/own/components/datatable/DownloadFile.vue";
import ListModal from "@/own/components/datatable/ListModal.vue";
import StarRating from "vue-star-rating";
import ShowServices from "@/own/components/datatable/ShowServices.vue";
import SocialMediaGroup from "@/own/components/datatable/SocialMediaGroup.vue";
import NamePhotoEmail from "@/own/components/datatable/NamePhotoEmail.vue";
import ShowRulesTable from "@/own/components/datatable/ShowRulesTable";
import ObjectList from "@/own/components/datatable/ObjectList";
import ImageTitleDescription from "@/own/components/datatable/ImageTitleDescription";
import ErrorLog from "@/own/components/datatable/ErrorLog";
import ShowTableWithTimeline from "@/own/components/datatable/ShowTableWithTimeline";
import ShowToken from "@/own/components/datatable/ShowToken.vue";
import ConfigurationPopup from "@/own/components/datahub/integrations2/ConfigurationPopup.vue";
import ShowDataFromApi from "@/own/components/datatable/ShowDataFromApi.vue";

export default {
  name: "ComponentSelector",
  props: {
    header: { required: true },
    item: { required: true },
    value: { required: true },
  },
  components: {
    ConfigurationPopup,
    ShowTableWithTimeline,
    ObjectList,
    ShowRulesTable,
    ShowFinServiceCustomers,
    StatusColumn,
    TextColumn,
    ClientColumn,
    ShowRoles,
    ShowWarehouses,
    DateColumn,
    DateTimeColumn,
    ShowSalePerson,
    ShowEntities,
    CodHiddenData,
    ShowHtmlPopUp,
    ShowCredentialPopup,
    CarrierPhoto,
    StoreItem,
    NameEmail,
    ShowImage,
    ShowClientSalePerson,
    ButtonLike,
    ShowUrlImage,
    PopUpDatatable,
    ShowSkusTable,
    ShowData,
    ValidationDialog,
    PlatformActions,
    ShowCustomContent,
    ShowMail,
    ColorGroup,
    DownloadFile,
    ListModal,
    StarRating,
    ShowServices,
    SocialMediaGroup,
    NamePhotoEmail,
    ShowOnlyImage,
    ImageTitleDescription,
    ErrorLog,
    ShowToken,
    ShowDataFromApi,
  },
};
</script>
