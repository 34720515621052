<template v-if="item">
  <div v-if="!testIgnore" :class="{ 'text-right': $vuetify.rtl }">
    <a
      href="#"
      class="btn btn-light-facebook btn-sm px-4 custom-btn text-no-wrap"
      @click="toggleModal"
      >{{ buttonText }}</a
    >
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="850"
      >
        <!--begin::Modal dialog-->
        <div class="bg-white poppins">
          <!--begin::Modal header-->
          <div class="modal-header align-center">
            <h3 class="mb-0 font-weight-bolder">{{ $t("details") }}</h3>
            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--begin::Modal header-->
          <!--begin::Modal body-->
          <div
            class="modal-body d-flex flex-column h-550px scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <json-view :data="serverData"></json-view>
            <!--end::Body-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal dialog-->
      </v-dialog>
    </div>
  </div>
  <span v-else class="badge font-size-sm my-1 py-2 px-4 badge-light-danger">
    {{ $t("test_ignore") }}
  </span>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "ShowDataFromApi",
  props: ["item", "header", "value"],
  data: () => ({
    dialog: false,
    testIgnore: false,
    serverData: null,
    typeToData: {
      payload_api_data: "payload",
      response_api_data: "response",
      payload_masked: "payload",
      header_masked: "header",
      response_masked: "response",
    },
  }),
  computed: {
    jsonData: function () {
      // TODO: translation effected condition
      return null;
    },
    buttonText: function () {
      let text = this.$t("view");
      // if (this.item) text = this.$t("no_data");
      return text;
    },
    showUrl() {
      if (this.$route.name == "wes.wesSplTransactionMonitoring") {
        return "/wes/wesSplTransactionMonitoring/show";
      } else if (this.$route.name == "datahub.http_transactions") {
        return "/datahub/http_transactions/show";
      } else {
        return null;
      }
    },
  },
  methods: {
    pageLoader(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    loadDataFromServer() {
      this.pageLoader(true);
      if (!this.showUrl) {
        return;
      }
      ApiService.post(this.showUrl, {
        id: this.item.id,
        column: this.header.value,
      })
        .then((response) => {
          this.serverData = response.data.value;
          // try {
          //   this.serverData = response.data.value;
          // } catch {
          //   this.serverData = {};
          // }

          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.buttonText == "No data") {
        return;
      } else if (this.dialog) {
        this.serverData = null;
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
  },
};
</script>
