<template>
  <div
    v-if="
      value === 'No Errors' ||
      value == null ||
      value == '' ||
      value == undefined
    "
    :class="{ 'text-right': $vuetify.rtl }"
  >
    <a
      class="btn btn-light-secondary text-dark btn-sm px-4 custom-btn text-no-wrap"
      @click="toggleModal"
      >{{ $t("no_errors") }}</a
    >
  </div>
  <div v-else :class="{ 'text-right': $vuetify.rtl }">
    <a
      class="btn btn-light-danger btn-sm px-4 custom-btn text-no-wrap"
      @click="toggleModal"
      >{{ $t("show_error") }}</a
    >
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        transition="dialog-bottom-transition"
        max-width="850"
      >
        <!--begin::Modal dialog-->
        <div class="bg-white poppins">
          <!--begin::Modal header-->
          <div class="modal-header align-center">
            <h3 class="mb-0 font-weight-bolder">{{ $t("error_message") }}</h3>
            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-light-info"
              @click="dialog = false"
            >
              <span class="svg-icon">
                <v-icon size="22">mdi-close</v-icon>
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--begin::Modal header-->
          <!--begin::Modal body-->
          <div
            class="modal-body d-flex flex-column min-h-100px scroll-y mx-5 mx-xl-15 py-7"
          >
            <!--begin::Body-->
            <div class="red--text">{{ value ?? "N/A" }}</div>
            <!--end::Body-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal dialog-->
      </v-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorLog",
  props: ["value"],
  data: () => ({
    dialog: false,
  }),
  methods: {
    toggleModal: function () {
      this.dialog = !this.dialog;
    },
  },
};
</script>
